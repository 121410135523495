<template>
    <div class="login_container">
<!--        color: String类型。默认'#dedede'。粒子颜色。-->
<!--        particleOpacity: Number类型。默认0.7。粒子透明度。-->
<!--        particlesNumber: Number类型。默认80。粒子数量。-->
<!--        shapeType: String类型。默认'circle'。可用的粒子外观类型有："circle","edge","triangle", "polygon","star"。-->
<!--        particleSize: Number类型。默认80。单个粒子大小。-->
<!--        linesColor: String类型。默认'#dedede'。线条颜色。-->
<!--        linesWidth: Number类型。默认1。线条宽度。-->
<!--        lineLinked: 布尔类型。默认true。连接线是否可用。-->
<!--        lineOpacity: Number类型。默认0.4。线条透明度。-->
<!--        linesDistance: Number类型。默认150。线条距离。-->
<!--        moveSpeed: Number类型。默认3。粒子运动速度。-->
<!--        hoverEffect: 布尔类型。默认true。是否有hover特效。-->
<!--        hoverMode: String类型。默认true。可用的hover模式有: "grab", "repulse", "bubble"。-->
<!--        clickEffect: 布尔类型。默认true。是否有click特效。-->
<!--        clickMode: String类型。默认true。可用的click模式有: "push", "remove", "repulse", "bubble"。-->
        <vue-particles
                color="#fff"
                :particleOpacity="0.7"
                :particlesNumber="120"
                shapeType="triangle"
                :particleSize="4"
                linesColor="#fff"
                :linesWidth="1"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="2"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
                style="height:100%"
        >
        </vue-particles>

        <a-row type="flex" justify="space-around" align="middle" class="logins animated fadeInLeft">
<!--            <div class="logos"></div>-->
            <h1>管理中心</h1>
            <h2> System Management Center</h2>

            <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form height-50"
                @submit="handleSubmit"
            >
                <a-form-item>
                    <a-input
                        v-decorator="['user_name',{initialValue:account, rules: [{ required: true, message: '请输入用户名!' }] }, ]"
                        placeholder="用户名"
                        allow-clear
                    >
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
                    </a-input>
                </a-form-item>
                <a-form-item has-feedback >
                    <a-input-password
                        v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入密码!' }] },
        ]"
                        type="password"
                        placeholder="登录密码"
                        allow-clear

                    >
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
                    </a-input-password>
                </a-form-item>
                <a-form-item>
                    <a-checkbox  class="Remember"
                                 v-decorator="[
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          },
        ]"
                    >记住用户名
                    </a-checkbox>
                    <!--            <a class="login-form-forgot" href="">-->
                    <!--                Forgot password-->
                    <!--            </a>-->
                    <a-button type="primary" html-type="submit" class="login-form-button">登录</a-button>
                    <!--            Or-->
                    <!--            <a href="">-->
                    <!--                register now!-->
                    <!--            </a>-->
                </a-form-item>
            </a-form>


        </a-row>
    </div>
</template>

<script>
    import Storage from "../common/storage";
    import {Modal} from "ant-design-vue";

    export default {
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: "normal_login"});
        },
        created() {
          console.log(localStorage)
          console.log("isloging: ", sessionStorage.isloging);
        },
        data() {
            return {
                account: Storage.account
            };
        },
        methods: {
            async doLogin({user_name, password, remember}) {

                let now = Date.now();
                let result = await this.$post("/api/admin/login", {
                    login_name: user_name,
                    password: password,
                    t: now,
                });
                console.log(result);
                if (result.status === true) {
                    Storage.uid = result.data.uid;
                    Storage.token = result.data.token;
                    // Storage.myName = result.data.name;
                    Storage.token_timeout = result.data.token_timeout;
                    sessionStorage.isloging = true;
                    Storage.rules = result.data.rules;
                    // Storage.company_id = result.data.company_id;
                    // Storage.permissions = result.data.permissions;
                    console.log("uid: ", Storage.uid);
                    console.log("token: ", Storage.token);
                    console.log("sessionStorage: ", sessionStorage.isloging);
                    // console.log("company_id: ", Storage.company_id);
                    // console.log("permissions: ", Storage.permissions);

                    if (remember) {
                        Storage.account = user_name;
                    } else {
                        Storage.account = "";
                    }
                    this.$router.push({path: "/"});
                }
                if(result.status === false){
                    Modal.info({
                        title: result.msg
                    });
                }

            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        this.doLogin(values);
                        console.log("Received values of form: ", values);
                    }
                });
            },

        }
    };
</script>
<style scoped>
    .bg {
        background-image: url("../assets/images/bg2.jpg");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        height: 100%;
        width: 100%;
    }

    #components-form-demo-normal-login .login-form {
        max-width: 300px;
    }

    #components-form-demo-normal-login .login-form-forgot {
        float: right;
    }

    #components-form-demo-normal-login .login-form-button {
        width: 100%;
    }

    .admin_login {
        margin: 0 auto;
    }

    .logins {
        padding: 20px;
        position: fixed;
        z-index: 2;
        left: 50%;
        top: 50%;
        margin-top: -300px;
        margin-left: -200px;
        border-radius: 5px;
        width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: rgba(255, 255, 255, .4);
        padding: 20px;
        z-index: 300;
    }

    .logins h2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        color: #171c60;
        border-bottom: 1px solid #991111;
        padding-bottom: 2px;
    }

    .logins h1 {
        width: 100%;
        text-align: center;
        color: #171c60;
    }
    .logos{
        background-image: url("../assets/images/logos.png");
        background-repeat: no-repeat;
        width: 150px;
        height: 150px;
        background-size: contain;
    }
    .logins /deep/ .ant-input {
        width: 319px;
        border-radius: 4px;
    }
    .logins /deep/ .Remember{color: #171c60}

    .fadeInLeft {
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
    }

    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        z-index: 100;
    }
    .login_container {
        background-image: linear-gradient(-180deg, #1a1454 0%, #0e81a5 100%);
        /*background-image: url("../images/bg_login.png");*/
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
    }
</style>
